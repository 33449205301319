import logo from "./logo.svg";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
} from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
// To properly import, make sure to select  Amplify  from  @aws-amplify/core  , and not from  aws-amplify ???
import config from './aws-exports';
Amplify.configure(config);

const signUpConfig = {
  header: 'Create a new account',
  hideAllDefaults: true,
  signUpFields: [
      {
          label: 'Email',
          key: 'email',
          required: true,
          displayOrder: 1,
          type: 'string'
      },
      {
          label: 'Last name',
          key: 'family_name',
          required: true,
          displayOrder: 2,
          type: 'string'
      },
      {
          label: 'First name',
          key: 'given_name',
          required: true,
          displayOrder: 3,
          type: 'string'
      },
      {
          label: 'Phone number',
          key: 'phone_number',
          required: true,
          displayOrder: 4,
          type: 'string'
      }
  ]
};

function App({ signOut, user }) {
  return (
    <View className="App">
      <Card>
        <Image src={logo} className="App-logo" alt="logo" />
        <Heading level={1}>We now have Auth!</Heading>
      </Card>
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
}

export default withAuthenticator(App, { signUpConfig });